import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'
import AcademyAvatar from '../Character/AcademyAvatar'

const Container = styled.div`
  display: flex;
  float: left;
  width: 100%;
  padding-top: 20px;
`

const Name = styled.div`
  text-align: left;
`

const Actions = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const IconAction = styled.span`
  padding-right: 15px;

  &:hover {
    cursor: pointer;
  }
`

const ListLink = styled.a`
  color: #fff;

  &:hover {
    color: #dc3545;
    text-decoration: none;
  }
`

class Friend extends Component {
  constructor(props) {
    super(props)

    this.state = {
      displayLeaveButtons: false
    }
  }

  render() {
    const {
      friend,
      onDelete,
      canDelete,
      onPromoteToOfficer,
      canPromote
    } = this.props

    return (
      <Container className="col-sm-12">
        <Name className="col-sm-9">
          {friend.academy && (
            <>
              <AcademyAvatar name={friend.academy.name} />
              &nbsp;
            </>
          )}
          Niv {friend.level} - {friend.name}{' '}
          <span style={{ color: '#f26725' }}>
            {friend.guildRole && friend.guildRole === 'master'
              ? '(Chef de guilde)'
              : friend.guildRole === 'officer'
              ? '(Officier)'
              : ''}
          </span>
        </Name>
        <Actions className="col-sm-3">
          <Link
            to={
              jwtDecode(Cookies.get('auth-token')).email === friend.email
                ? '/character'
                : '/character/' + friend.id
            }
          >
            <IconAction data-tip="Visualiser">
              <i className="far fa-address-card text-success" />
            </IconAction>
          </Link>
          {friend.guildRole !== 'master' && canPromote && (
            <IconAction
              data-tip={
                friend.guildRole === 'officer' ? 'Rétrograder' : 'Promouvoir'
              }
              onClick={() => onPromoteToOfficer(friend)}
            >
              <i
                className={`fas fa-user-graduate ${
                  friend.guildRole === 'officer'
                    ? 'text-danger'
                    : 'text-warning'
                }`}
              />
            </IconAction>
          )}
          {(canDelete ||
            jwtDecode(Cookies.get('auth-token')).email === friend.email) && (
            <>
              <IconAction
                data-tip="Supprimer"
                onClick={() =>
                  this.setState({
                    displayLeaveButtons: !this.state.displayLeaveButtons
                  })
                }
              >
                <i className="fas fa-times text-danger" />
              </IconAction>
              <div
                className={this.state.displayLeaveButtons ? '' : 'd-none'}
                style={{ fontSize: '12px' }}
              >
                <ListLink
                  className="text-success"
                  href="#friendsTab"
                  onClick={() => onDelete(friend)}
                >
                  Valider
                </ListLink>{' '}
                -{' '}
                <ListLink
                  href="#leave-cancel"
                  onClick={() =>
                    this.setState({
                      displayLeaveButtons: false
                    })
                  }
                >
                  Annuler
                </ListLink>
              </div>
            </>
          )}
        </Actions>
        <ReactTooltip />
      </Container>
    )
  }
}

Friend.propTypes = {
  friend: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    level: PropTypes.number,
    image: PropTypes.string,
    guildRole: PropTypes.string,
    academy: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  onDelete: PropTypes.func,
  canDelete: PropTypes.bool,
  onPromoteToOfficer: PropTypes.func,
  canPromote: PropTypes.bool
}

export default Friend
